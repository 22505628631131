// Google Font family

@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&family=Caveat:wght@400;500;600;700&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

// Variables for style

:root {

    // Colors

    --primary-color: #fbd499;
    --secondary-color: #F7931A;
    --title-color: #ffffff;
    --text-color: #ebebeb;
    --bg-color: #212730;
    --bg-color-alt: #30353b;
    --container-color: #1d222a;
    --border-color: #a4a6a8;

    // Grediant Colors

    --first-grediant: linear-gradient(0deg, var(--bg-color-alt) 0%, var(--bg-color) 100%);
    --second-grediant: linear-gradient(180deg, var(--bg-color-alt) 0%, var(--bg-color) 100%);
    --third-grediant: linear-gradient(180deg, var(--bg-color) 0%, var(--bg-color) 100%);

    // Tyopography Family

    --body-font: 'Jost', sans-serif;
    --second-font: 'Caveat', cursive;
    --cursive-brush-font: 'Caveat Brush', cursive;
    --logo-font: 'Merriweather', serif;

    // Tyopography Sizes

    --biggest-font-size: clamp(2.25rem, 1.7679rem + 2.4107vw, 5.625rem);
    --h1-font-size: clamp(1.75rem, 1.6071rem + 0.7143vw, 2.75rem);
    --h2-font-size: 1.875rem;
    --h3-font-size: 1.75rem;
    --h4-font-size: 1.5rem;
    --h5-font-size: 1.375rem;
    --largest-font-size: clamp(0.75rem, 0.6696rem + 0.4018vw, 1.3125rem);
    --larger-font-size: 1.25rem;
    --large-font-size: 1.125rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.938rem;
    --smaller-font-size: 0.875rem;
    --tiny-font-size: 0.813rem;

    --back-font-size: clamp(7.5rem, 5.4464rem + 10.2679vw, 21.875rem);

    // Shadow Colors

    --shadow: 5px 5px rgb(255 255 255 / 10%);

    // Transition

    --transition: cubic-bezier(0.3, 0, 0.3, 1);

}

.light-theme {
    --primary-color: #29a385;
    --secondary-color: #3D9970;
    --title-color: #000000;
    --text-color: #262626;
    --bg-color: #f0ebe3;
    --bg-color-alt: #ffffff;
    --container-color: #ffffff;
    --border-color: #000000;


    .skills__bar {
        background: rgba($color: #000000, $alpha: .1);
    }

    .skills__percentage {
        background: var(--border-color);

        span {
            background-color: var(--primary-color);
        }
    }

    .shape {
        filter: invert(0);
        opacity: 1;
    }

    .bg__title {
        color: rgba($color: #000000, $alpha: 0.02);
    }

    .style__switcher-toggler {
        box-shadow: 6px 6px 3px #cccccc;
    }

    // Shadow Colors

    --shadow: 5px 5px rgb(0 0 0 / 20%);
}