@import "../../variable.scss";

.header {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 20px 30px;
    z-index: 100;

    .nav {
        display: flex;
        justify-content: space-between;

        .nav__logo {
            font-family: var(--logo-font);
            color: var(--title-color);
            font-size: var(--h4-font-size);
            font-weight: 700;
            cursor: pointer;
        }
    }

    .nav__menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 0;
        height: 100vh;
        display: flex;
        background-color: var(--bg-color-alt);
        overflow: hidden;
        transition: all 1s var(--transition);
        z-index: 10;

        &.show-menu{
            width: 512px;
        }

        .nav__data {
            padding: 80px 84px 0 154px;
            margin: auto;
            width: 100%;

            .nav__list {
                margin-bottom: 40px;

                li {
                    margin-bottom: 20px;
                }

                .nav__link {
                    color: var(--title-color);
                    font-size: var(--h5-font-color);
                    font-weight: 700;
                    cursor: pointer;
                    line-height: 30px !important;
                    transition: all 0.3s var(--transition);
                    &:hover {
                        color: var(--primary-color);
                        font-size: 1.4rem !important;
                    }
                }
            }
        }

        .header__socials {
            display: flex;
            column-gap: 20px;
            margin-bottom: 30px;
            line-height: 40px;

            .header__social-link {
                color: var(--title-color);
                font-size: var(--h5-font-size);
                line-height: 32px !important;
                transition: all 0.7s var(--transition);

                &.link1:hover {
                    color: var(--secondary-color) !important;
                }

                &.link2:hover {
                    color: #25D366 !important;
                }

                &.link3:hover {
                    color: #0077b5 !important;
                }

                &.link4:hover {
                    color: #6e5494 !important;
                }

                &.link5:hover {
                    color: #000000 !important;
                }

            }
        }
    }

    .nav__btns {
        display: flex;
        align-items: center;
        column-gap: 40px;

        .theme__toggle{
            font-size: var(--h4-font-size);
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .nav__toggle {
            height: 30px;
            width: 28px;
            position: relative;
            z-index: 100;

            span {
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: var(--title-color);
                transition: all .3s var(--transition);

                &:first-child {
                    top: 8px;
                }

                &:last-child {
                    bottom: 8px;
                }
            }

            &.animated-toggle{
                span{
                    &:first-child{
                        transform: rotate(-45deg);
                        top: 14px;
                    }
                    &:last-child{
                        transform: rotate(45deg);
                        top: 14px;
                    }
                }
            }
        }

        .theme-toggle, .nav__toggle{
            cursor: pointer;
        }
    }
}

.scroll-header{
    position: fixed;
    background-color: var(--bg-color-alt);
    animation: header_animate 0.8s var(--transition) 0s forwards; 
}

@keyframes header_animate {
    0% {
        transform: translateY(-100px);
    }
    100%{
        transform: translateY(0);
    }
}