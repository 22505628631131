@import "../../variable.scss";

.testimonials {
    background-image: var(--third-grediant);

    .testimonials__container {
        text-align: center;
    }

    .testimonials_header {
        position: relative;
    }

    .testimonials__img {
        height: 210px;
        width: 100%;
        border-radius: 18px;
        object-fit: cover;
        margin-bottom: 30px;
        vertical-align: middle;
    }

    .testimonials__icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 66px;
        height: 66px;
        background-color: var(--container-color);
        border-bottom-right-radius: 18px;

        img {
            position: relative;
            left: 12px;
            top: 18px;
        }
    }

    .testimonials__description {
        min-height: 110px;
        text-align: justify;
    }

    .testimonials__name {
        font-size: var(--largest-font-size);
        margin-top: 30px;
    }

    .swiper-horizontal>.swiper-pagination-bullets {
        width: auto;
        position: relative;
        display: inline-block;

        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(50%);
            height: 2px;
            width: 100%;
            background-color: var(--title-color);
        }
    }

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        border: 2px solid var(--title-color);
        background-color: var(--container-color);
        z-index: 1;
        position: relative;
        opacity: 1;
        margin: 0;
        transition: all 0.3s var(--transition);

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active {
        background-color: var(--primary-color);
        width: 12px;
        height: 12px;
        top: 2px;
    }
}