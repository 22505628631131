@media only screen and (min-width: 1920px) {
    html, body, header, .main-hero-section, nav {
        max-width: 1920px;
        margin: 0 auto;
    }
}


@media screen and (max-width: 1440px) {
    .section__deco:not(.header__deco) {
        display: none !important;
    }

    .header {
        padding: 20px 12px !important;
    }

    .home__img-wrapper {
        max-width: 600px !important;
        transform: translateY(-6%) !important;
    }

    .home__profile {
        height: auto !important;
    }

    .home__data {
        transform: scale(0.9) !important;
    }
    // .style__switcher {
    //     top: 10% !important;
    //     right: 0% !important;
    //     left: 0% !important;
    // }

    // .style__switcher-toggler {
    //     right: 78% !important;
    //     top: 10% !important;
    //     height: 50px !important;
    //     background-color: var(--container-color);
    //     width: 50px !important;
    //     box-shadow: var(--shadow);
    // }
}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 960px !important;
    }

    .home {
        padding-top: 140px !important;
    }

    .home__subtitle,
    .home__title,
    .home__job,
    .home__text {
        text-align: center !important;
    }

    .home__text {
        margin-inline: auto !important;
    }

    .home__img-wrapper {
        position: relative !important;
        transform: initial !important;
        right: initial !important;
        margin-inline: auto !important;
        margin-top: 140px !important;
    }

    .home__socials,
    .home__btns {
        justify-content: center !important;
    }

    .home__mouse {
        display: flex !important;
        justify-content: center !important;
    }

    .skills__container,
    .portfolio__container,
    .plans__container {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .footer__copyright:nth-child(2) {
        justify-self: start !important;
    }

    .footer .footer__copyright:nth-child(3) {
        justify-self: center !important;
    }
}

@media screen and (max-width: 992px) {
    :root {
        --h2-font-size: 24px !important;
        --h3-font-size: 23px !important;
        --h4-font-size: 21px !important;
        --h5-font-size: 20px !important;
        --largest-font-size: 19px !important;
        --larger-font-size: 18px !important;
        --large-font-size: 16px !important;
        --normal-font-size: 15px !important;
        --small-font-size: 14px !important;
        --smaller-font-size: 13px !important;
        --tiny-font-size: 12px !important;

    }

    .container {
        max-width: 740px !important;
    }

    .home__btns {
        flex-direction: column !important;
        align-items: center !important;
        row-gap: 60px !important;
    }

    .hero__link {
        &::before {
            left: 50% !important;
            top: -72% !important;
            transform: translateX(-50%);
            width: 2px !important;
            height: 40px !important;
        }
    }

    .skills__container,
    .portfolio__container,
    .plans__container {
        gap: 30px !important;
    }

    .portfolio__img {
        height: 200px !important;
    }

    .resume__container {
        grid-template-columns: 1fr !important;
        gap: 40px !important;
    }

    .resume__icon {
        width: 40px !important;
        height: 40px !important;
        right: -20px !important;
    }

    .contact__form-group {
        grid-template-columns: 1fr !important;
        row-gap: 0 !important;
    }

    .contact__card-icon {
        font-size: var(--large-font-size) !important;
        width: 42px !important;
        height: 42px !important;

        &::after {
            width: 32px !important;
        }
    }

    .contact__card {
        padding-left: 100px !important;
    }

    .footer__container {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;

        &.grid {
            gap: 10px !important;
        }
    }

    .footer__copyright:nth-child(2),
    .footer__copyright:last-child {
        justify-self: center !important;
    }
    
    .container {
        padding: 0px 24px !important;
    }

    .resume .resume__heading, .resume .resume__item{
        margin-right: 20px !important;
    }

    .resume__item {
        border-right: 1px solid var(--border-color) !important;
    }

    .section__bg-wrapper{
        bottom: 0px !important;
    }
}

@media screen and (max-width: 768px) {
    .header {
        padding: 18px 12px !important;
    }

    .nav__data {
        padding: 80px 0 0 124px !important;
    }

    .show-menu {
        width: 420px !important;
    }

    .header__deco {
        left: 60px !important;
    }

    .container {
        max-width: 540px !important;
    }

    .home__img-wrapper {
        margin-top: 120px !important;
        max-width: 400px !important;
    }

    .home__data {
        transform: scale(0.6) !important;
    }

    .home__data-one {
        left: -12% !important;
    }

    .home__data-two {
        right: -7% !important;
    }

    .shape__1 {
        height: 115px !important;
        width: 115px !important;
        right: 9% !important;
    }

    .shape__2,
    .shape__3 {
        width: 72px !important;
        height: 72px !important;
    }

    .shape__2 {
        bottom: 10% !important;
        left: -10% !important;
    }

    .shape__3 {
        bottom: 4% !important;
        right: -1% !important;
    }

    .skills__container,
    .portfolio__container,
    .plans__container,
    .contact__container {
        grid-template-columns: 1fr !important;
    }

    .portfolio__list {
        column-gap: 24px !important;
    }

    .portfolio__list-item {
        &::before {
            bottom: -8px !important;
        }
    }

}

@media screen and (max-width: 576px) {
    .show-menu {
        width: 100% !important;
    }

    .nav__btns {
        column-gap: 24px !important;
    }

    .home__img-wrapper {
        margin-top: 100px !important;
    }

    .resume__header {
        padding: 28px 24px !important;
    }

    .resume__content {
        padding-inline: 24px 30px !important;
    }

    .resume__date-title {
        flex-direction: column !important;
        align-items: start !important;
        row-gap: 4px !important;
    }

    .resume.section {
        padding-inline: 0px 20px !important;
    }

    .footer__copyright.footer__max-content {
        display: flex !important;
        flex-wrap: wrap !important;
        width: inherit !important;
        justify-content: center !important;

        span {
            margin-left: 6px !important;
        }
    }
}

@media screen and (max-width: 395px) {
    .nav__data {
        padding: 80px 0 0 90px !important;
    }

    .header__deco {
        left: 40px !important;
    }

    .home__img-wrapper {
        margin-top: 80px !important;
    }

    .home__data {
        transform: scale(0.5) !important;
    }

    .home__data-one {
        bottom: 18% !important;
        left: -18% !important;
    }

    .home__data-two {
        bottom: 0 !important;
        left: -12% !important;
    }

    .card-one,
    .card-two {
        padding: 24px !important;
    }

    .skills__titles,
    .skills__description {
        padding-inline: 16px !important;
    }

    .portfolio__img {
        height: 180px !important;
    }

    .resume__header {
        padding: 24px 18px !important;
    }

    .resume__content {
        padding-inline: 18px 20px !important;
    }

    .testimonial__img {
        height: 140px !important;
    }

    .portfolio__container .shape.c__shape {
        bottom: -64px !important;
    }
}

@media only screen and (max-width: 319px) {
    html, body, header, .main-hero-section, nav {
        max-width: 319px;
        margin: 0 auto;
    }
}