@import "../../variable.scss";

.home {
    background-image: var(--first-grediant);
    padding-bottom: 180px;
    position: relative;

    .home__wrapper {
        min-height: 100vh;
        display: grid;
        align-items: center;
        position: relative;
    }

    .home__container {
        width: 100%;
        position: relative;
        z-index: 10;
        margin: 160px auto 60px;
    }

    .home__subtitle,
    .home__job {
        font-weight: 700;
    }

    .home__subtitle {
        span {
            color: var(--primary-color);
        }
    }

    h1.home__title {
        color: var(--primary-color);
    }

    .home__subtitle {
        font-size: var(--small-font-size);
        color: var(--title-color);
    }

    .home__job {
        span {
            font-size: var(--small-font-size);
            color: var(--title-color);
        }

        b {
            font-family: var(--second-font);
            font-size: var(--h2-font-size);
            margin-left: 10px;
        }
    }

    .home__title {
        color: #fff;
        font-size: var(--biggest-font-size);
        text-shadow: 2px 2px #000000, -2px 2px #000000, 2px -2px #000000, -2px -2px #000000;
        line-height: 1.2;
        margin-top: 15px;
    }

    .home__text {
        font-size: var(--large-font-size);
        margin-block: 40px 28px;
        max-width: 520px;
    }

    .home__socials {
        display: flex;
        column-gap: 20px;
        margin-bottom: 30px;
        line-height: 40px;

        .home__social-link {
            color: var(--title-color);
            font-size: var(--h5-font-size);
            transition: all 0.7s var(--transition);

            &.link1:hover {
                color: var(--secondary-color) !important;
                font-size: 30px !important;
            }

            &.link2:hover {
                color: #25D366 !important;
                font-size: 30px !important;
            }

            &.link3:hover {
                color: #0077b5 !important;
                font-size: 30px !important;
            }

            &.link4:hover {
                color: #6e5494 !important;
                font-size: 30px !important;
            }

            &.link5:hover {
                color: #000000 !important;
                font-size: 30px !important;
            }

        }
    }

    .home__btns {
        display: flex;
        column-gap: 70px;
    }

    .hero__link::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -128%;
        transform: translateY(-50%);
        width: 40px;
        height: 2px;
        background-color: var(--border-color);
    }

    .home__img-wrapper {
        position: absolute;
        top: 30px;
        right: 12px;
        transform: translateY(-8%);
        max-width: 720px;
        width: 100%;

        .home__banner {
            background: var(--primary-color);
            padding-bottom: 100%;
            border-radius: 50%;
            position: relative;
            z-index: 1;

            .home__profile {
                position: absolute;
                bottom: 0;
                height: 800px;
                border-radius: 500px;
                object-fit: cover;
            }
        }
    }

    .item {
        width: 70px;
        height: 120px;
        position: relative;
    }

    .home__mouse {
        transition: var(--transition);
    }

    .mouse {
        width: 40px;
        cursor: pointer;
        height: 60px;
        border-radius: 20px;
        border: 3px solid var(--border-color);
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 20px);

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
        }

        &.m-5 {
            &:before {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                top: 7px;
                left: calc(50% - 7px);
                border: 2px solid var(--title-color);
                opacity: .4;
            }

            &:after {
                top: 30px;
                left: calc(50% - 5px);
                border: 5px solid transparent;
                border-top-color: var(--border-color);
                animation: m-5-arrow 1.2s ease-in-out infinite;
            }
        }
    }

    .home__data {
        border: 2px solid var(--border-color);
        background-color: var(--container-color);
        box-shadow: var(--shadow);
        padding-inline: 20px;
        width: 240px;
        height: 82px;
        border-radius: 82px;
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 1;

        &.home__data-one {
            bottom: 20%;
            left: -4%;
        }

        &.home__data-two {
            bottom: 12%;
            right: 6%;
        }

        .text-lg,
        .text-sm {
            width: 50%;
            color: var(--title-color);
            font-weight: 700;
        }

        .text-lg {
            text-align: center;
            font-size: 37px;

            b {
                position: relative;
                top: -4px;
                color: var(--primary-color);
            }
        }

        .text-sm {
            font-size: 13px;
            line-height: 1.4em;

            span {
                color: var(--primary-color);
            }
        }
    }

    .shape__1 {
        height: 225px;
        width: 225px;
        top: -4%;
        right: 6%;
    }

    .shape__2,
    .shape__3 {
        width: 140px;
        height: 140px;
    }

    .shape__2 {
        bottom: 8%;
        left: -16%;
    }

    .shape__3 {
        bottom: 0;
        right: -2%;
    }
}

@keyframes m-5-arrow {

    20%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(6px);
    }

    80% {
        transform: translateY(-3px);
    }
}