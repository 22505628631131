@import "../../variable.scss";

.style__switcher {
    position: fixed;
    left: 0%;
    top: 14%;
    background-color: var(--container-color);
    width: 220px;
    padding: 15px;
    box-shadow: var(--shadow);
    z-index: 200;
    transform: translateX(-100%);
    transition: all 0.3s ease;
}

.show__switcher {
    transform: translateX(0);
}

.style__switcher,
.style__switcher-toggler {
    border-radius: 0 5px 5px 0;
}

.style__switcher-items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 4px;
    row-gap: 8px;
}

.style__switcher-title,
.style__switcher-toggler,
.style__switcher-close {
    color: var(--title-color);
}

.style__switcher-title {
    font-size: var(--small-font-size);
    font-weight: 600;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.color__img {
    width: 30px;
    cursor: pointer;
}

.style__switcher-toggler {
    position: absolute;
    left: 100%;
    top: 8%;
    height: 50px;
    width: 50px;
    font-size: var(--h4-font-size);
    cursor: pointer;
    display: grid;
    place-items: center;
    background-color: var(--container-color);
    width: 50px !important;
    box-shadow: 6px 6px 3px #30353b;
    transition: all 0.6s ease;

    & > svg {
        fill: var(--text-color);
        animation: spin 8s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.style__switcher-close {
    cursor: pointer;
    font-size: var(--h2-font-size);
    position: absolute;
    top: 2%;
    right: 15px;
}